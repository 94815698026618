import React from 'react';
import Navigation from './Navigation';

const Header = () => {
  return (
    <header className="bg-white shadow-sm">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center h-20 sm:h-24">
          {/* Logo - Dengan ukuran responsif */}
          <div className="flex items-center">
            <img
              src="/assets/images/logodigiday.png"
              alt="Logo"
              className="h-10 sm:h-12 md:h-14 lg:h-16 w-auto object-contain"
            />
          </div>

          {/* Navigation Component */}
          <Navigation />

          {/* CTA Button */}
          <div className="hidden md:block">
            <a
              href="/contact"
              className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 text-sm lg:text-base"
            >
              Konsultasi Gratis
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;