// pages/admin/Settings.js
import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { Save } from 'lucide-react';

const Settings = () => {
  const [settings, setSettings] = useState({
    general: {
      siteName: 'Your Company',
      tagline: 'Your Tagline',
      email: 'info@company.com',
      phone: '+1234567890'
    },
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: ''
    },
    appearance: {
      logo: null,
      favicon: null,
      primaryColor: '#0056b3',
      secondaryColor: '#6c757d'
    }
  });

  return (
    <AdminLayout>
      <div className="max-w-4xl mx-auto space-y-6">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Pengaturan Website</h1>
            <p className="text-gray-500">Kelola pengaturan umum website Anda</p>
          </div>
          <button className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 flex items-center">
            <Save className="w-5 h-5 mr-2" />
            Simpan Perubahan
          </button>
        </div>

        {/* General Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold mb-4">Pengaturan Umum</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nama Website
              </label>
              <input
                type="text"
                value={settings.general.siteName}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    general: { ...settings.general, siteName: e.target.value }
                  })
                }
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
              />
            </div>
            {/* Tambahkan input lainnya */}
          </div>
        </div>

        {/* Social Media Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold mb-4">Social Media</h2>
          {/* Tambahkan form social media */}
        </div>

        {/* Appearance Settings */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold mb-4">Tampilan</h2>
          {/* Tambahkan pengaturan tampilan */}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Settings;