import React from 'react';
import { CheckCircle, Users, Trophy, Building } from 'lucide-react';

const About = () => {
  const stats = [
    { label: 'Tahun Pengalaman', value: '5+' },
    { label: 'Project Selesai', value: '100+' },
    { label: 'Klien Puas', value: '50+' },
    { label: 'Team Member', value: '20+' },
  ];

  const values = [
    {
      icon: CheckCircle,
      title: 'Kualitas',
      description: 'Kami berkomitmen untuk memberikan layanan berkualitas tinggi'
    },
    {
      icon: Users,
      title: 'Kolaborasi',
      description: 'Bekerja sama dengan klien untuk hasil terbaik'
    },
    {
      icon: Trophy,
      title: 'Inovasi',
      description: 'Selalu menghadirkan solusi inovatif dan terkini'
    },
    {
      icon: Building,
      title: 'Profesional',
      description: 'Tim profesional dengan pengalaman di bidangnya'
    },
  ];

  const timeline = [
    {
      year: '2019',
      title: 'Awal Perjalanan',
      description: 'Perusahaan didirikan dengan fokus pada pengembangan software'
    },
    {
      year: '2020',
      title: 'Ekspansi Layanan',
      description: 'Menambahkan layanan digital marketing dan desain'
    },
    {
      year: '2021',
      title: 'Pertumbuhan Tim',
      description: 'Ekspansi tim dan membuka kantor baru'
    },
    {
      year: '2022',
      title: 'Inovasi Teknologi',
      description: 'Mengadopsi teknologi terbaru dan metodologi modern'
    },
    {
      year: '2023',
      title: 'Pencapaian Baru',
      description: 'Mencapai 100+ project sukses dan 50+ klien puas'
    },
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative py-20 bg-primary">
        <div className="absolute inset-0">
          <img
            src="/assets/images/about-hero.jpg"
            alt="About Us"
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        <div className="relative container mx-auto px-4 sm:px-6 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Tentang Kami
          </h1>
          <p className="text-xl text-white/90 max-w-2xl mx-auto">
            Membangun masa depan digital dengan inovasi dan kreativitas
          </p>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat) => (
              <div key={stat.label} className="text-center">
                <p className="text-4xl font-bold text-primary mb-2">{stat.value}</p>
                <p className="text-gray-600">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-16">
        <div className="container mx-auto px-4 sm:px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Nilai-Nilai Kami</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value) => {
              const Icon = value.icon;
              return (
                <div key={value.title} className="text-center">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/10 text-primary mb-4">
                    <Icon className="h-8 w-8" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Perjalanan Kami</h2>
          <div className="max-w-3xl mx-auto">
            {timeline.map((item, index) => (
              <div key={item.year} className="relative pl-8 pb-8 border-l-2 border-primary last:pb-0">
                <div className="absolute left-0 top-0 w-4 h-4 bg-primary rounded-full -translate-x-1/2"></div>
                <div className="mb-6">
                  <span className="text-sm text-primary font-semibold">{item.year}</span>
                  <h3 className="text-xl font-semibold mt-1">{item.title}</h3>
                  <p className="text-gray-600 mt-2">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-16">
        <div className="container mx-auto px-4 sm:px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Tim Kami</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Add team members here */}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="container mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Mari Bekerja Sama
          </h2>
          <p className="text-white/90 mb-8 max-w-2xl mx-auto">
            Kami siap membantu mengembangkan bisnis Anda dengan solusi digital terbaik
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-primary font-bold py-3 px-8 rounded-lg hover:bg-gray-100 transition-colors"
          >
            Hubungi Kami
          </a>
        </div>
      </section>
    </div>
  );
};

export default About;