import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  // Check if user is logged in
  const isAuthenticated = localStorage.getItem('authToken');
  
  // If not logged in, redirect to login page
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // If logged in, render child routes
  return <Outlet />;
};

export default ProtectedRoute;