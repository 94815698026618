import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Star } from 'lucide-react';
import 'swiper/css';
import 'swiper/css/pagination';

const TestimonialsSection = () => {
  const testimonials = [
    {
      id: 1,
      name: 'PT. Maju Bersama',
      role: 'Client - E-Commerce',
      image: '/assets/images/client-1.png',
      content: 'Pelayanan yang sangat profesional. Tim teknis sangat membantu dalam proses pengembangan website e-commerce kami.',
      rating: 5
    },
    {
      id: 2,
      name: 'Rumah Sakit Sehat',
      role: 'Client - Healthcare',
      image: '/assets/images/client-2.png',
      content: 'Sistem informasi yang dikembangkan sangat membantu operasional rumah sakit kami. Terima kasih atas kerjasamanya.',
      rating: 5
    },
    {
      id: 3,
      name: 'Bank Digital',
      role: 'Client - Fintech',
      image: '/assets/images/client-3.png',
      content: 'Tim kreatif memberikan solusi yang inovatif untuk kampanye digital kami. Hasil yang memuaskan!',
      rating: 5
    },
    {
      id: 4,
      name: 'Restaurant Chain',
      role: 'Client - F&B',
      image: '/assets/images/client-4.png',
      content: 'Aplikasi POS yang dikembangkan sangat user-friendly dan membantu operasional restaurant kami.',
      rating: 5
    }
  ];

  const partners = [
    '/assets/images/partner-1.png',
    '/assets/images/partner-2.png',
    '/assets/images/partner-3.png',
    '/assets/images/partner-4.png',
    '/assets/images/partner-5.png',
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6">
        {/* Header */}
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Apa Kata Klien Kami
          </h2>
          <p className="text-lg text-gray-600">
            Testimoni dari klien yang telah mempercayakan projectnya kepada kami
          </p>
        </div>

        {/* Testimonials Slider */}
        <div className="mb-16">
          <Swiper
            modules={[Autoplay, Pagination]}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            loop={true}
            className="pb-12"
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial.id}>
                <div className="bg-white p-6 rounded-lg shadow-md">
                  {/* Rating */}
                  <div className="flex mb-4">
                    {[...Array(testimonial.rating)].map((_, index) => (
                      <Star
                        key={index}
                        className="w-5 h-5 text-yellow-400 fill-current"
                      />
                    ))}
                  </div>
                  
                  {/* Content */}
                  <p className="text-gray-600 mb-6">
                    "{testimonial.content}"
                  </p>
                  
                  {/* Client Info */}
                  <div className="flex items-center">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div className="ml-4">
                      <h4 className="font-semibold">{testimonial.name}</h4>
                      <p className="text-sm text-gray-500">{testimonial.role}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Partners */}
        <div>
          <h3 className="text-center text-xl font-semibold mb-8">
            Partner Kami
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 items-center">
            {partners.map((partner, index) => (
              <div
                key={index}
                className="flex items-center justify-center p-4 grayscale hover:grayscale-0 transition-all"
              >
                <img
                  src={partner}
                  alt={`Partner ${index + 1}`}
                  className="max-h-12 w-auto"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;