import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import {
  BarChart as BarChartIcon,
  TrendingUp,
  Users,
  Clock,
  Globe,
  MousePointer,
  ArrowUpRight,
  ArrowDownRight,
  Calendar,
} from 'lucide-react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const Analytics = () => {
  const [dateRange, setDateRange] = useState('7days');

  // Dummy data untuk statistik
  const stats = [
    {
      title: 'Total Visitors',
      value: '45,021',
      change: '+12.5%',
      isIncrease: true,
      icon: Users,
    },
    {
      title: 'Page Views',
      value: '98,542',
      change: '+8.2%',
      isIncrease: true,
      icon: MousePointer,
    },
    {
      title: 'Avg. Session Duration',
      value: '3m 45s',
      change: '-2.4%',
      isIncrease: false,
      icon: Clock,
    },
    {
      title: 'Bounce Rate',
      value: '42.3%',
      change: '-5.1%',
      isIncrease: true,
      icon: TrendingUp,
    },
  ];

  // Data untuk grafik pengunjung
  const visitorData = [
    { date: '2024-01-01', visitors: 1500, pageviews: 3200 },
    { date: '2024-01-02', visitors: 1800, pageviews: 3800 },
    { date: '2024-01-03', visitors: 1600, pageviews: 3400 },
    { date: '2024-01-04', visitors: 2100, pageviews: 4200 },
    { date: '2024-01-05', visitors: 1900, pageviews: 3900 },
    { date: '2024-01-06', visitors: 1700, pageviews: 3600 },
    { date: '2024-01-07', visitors: 2200, pageviews: 4500 },
  ];

  // Data untuk sumber traffic
  const trafficSourceData = [
    { name: 'Direct', value: 35, color: '#0088FE' },
    { name: 'Organic Search', value: 40, color: '#00C49F' },
    { name: 'Social Media', value: 15, color: '#FFBB28' },
    { name: 'Referral', value: 10, color: '#FF8042' },
  ];

  // Data untuk halaman populer
  const popularPages = [
    {
      page: '/home',
      views: 12543,
      uniqueVisitors: 8234,
      avgTime: '2:30',
    },
    {
      page: '/services',
      views: 8765,
      uniqueVisitors: 5432,
      avgTime: '1:45',
    },
    {
      page: '/blog/top-10-tips',
      views: 6543,
      uniqueVisitors: 4321,
      avgTime: '3:15',
    },
    {
      page: '/contact',
      views: 4321,
      uniqueVisitors: 3234,
      avgTime: '1:20',
    },
  ];

  return (
    <AdminLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Analytics</h1>
            <p className="text-gray-500">Monitor your website performance</p>
          </div>
          
          {/* Date Range Selector */}
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
          >
            <option value="7days">Last 7 Days</option>
            <option value="30days">Last 30 Days</option>
            <option value="3months">Last 3 Months</option>
            <option value="12months">Last 12 Months</option>
          </select>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat) => {
            const Icon = stat.icon;
            return (
              <div key={stat.title} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="p-2 rounded-lg bg-primary/10">
                    <Icon className="w-6 h-6 text-primary" />
                  </div>
                  <div className="flex items-center">
                    <span className={`text-sm ${
                      stat.isIncrease ? 'text-green-600' : 'text-red-600'
                    }`}>
                      {stat.change}
                    </span>
                    {stat.isIncrease ? (
                      <ArrowUpRight className="w-4 h-4 text-green-600" />
                    ) : (
                      <ArrowDownRight className="w-4 h-4 text-red-600" />
                    )}
                  </div>
                </div>
                <h3 className="text-2xl font-bold mb-1">{stat.value}</h3>
                <p className="text-gray-600">{stat.title}</p>
              </div>
            );
          })}
        </div>

        {/* Charts Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Visitor Trend Chart */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Visitor Trends</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={visitorData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="visitors"
                    stroke="#0088FE"
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="pageviews"
                    stroke="#00C49F"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Traffic Sources Chart */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Traffic Sources</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={trafficSourceData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label
                  >
                    {trafficSourceData.map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Popular Pages Table */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Popular Pages</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Page
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Page Views
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Unique Visitors
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Avg. Time on Page
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {popularPages.map((page, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {page.page}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {page.views.toLocaleString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {page.uniqueVisitors.toLocaleString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{page.avgTime}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Analytics;