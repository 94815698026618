import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
// Import individual icons yang dibutuhkan saja
import {
  Package,
  Search,
  Settings,
  X,
  AlertCircle,
  CheckCircle,
  RefreshCw,
  Plus,
  Grid,
  Power // untuk toggle
} from 'lucide-react';

const Plugins = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState(null);
  const [view, setView] = useState('grid');

  // Dummy data untuk plugins
  const [plugins] = useState([
    {
      id: 1,
      name: 'SEO Manager',
      description: 'Optimize your content for search engines with advanced SEO tools.',
      version: '1.2.0',
      author: 'Digital Tools Inc',
      status: 'active',
      icon: '/assets/images/plugins/seo-icon.png',
      settings: {
        enableAutoSEO: true,
        generateSitemap: true,
        metaDescriptionLength: 160
      }
    },
    // ... plugin data lainnya
  ]);

  // Filter plugins based on search
  const filteredPlugins = plugins.filter(plugin =>
    plugin.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plugin.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Render plugin card component
  const PluginCard = ({ plugin }) => (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center">
          <Package className="w-6 h-6 text-primary" />
        </div>
        <div>
          <span className={`px-2 py-1 text-xs rounded-full ${
            plugin.status === 'active'
              ? 'bg-green-100 text-green-800'
              : 'bg-gray-100 text-gray-800'
          }`}>
            {plugin.status}
          </span>
        </div>
      </div>
      
      <h3 className="text-lg font-semibold mb-2">{plugin.name}</h3>
      <p className="text-gray-600 mb-4">{plugin.description}</p>
      
      <div className="text-sm text-gray-500 mb-4">
        <div>Version: {plugin.version}</div>
        <div>By: {plugin.author}</div>
      </div>
      
      <div className="flex items-center justify-between">
        <button
          onClick={() => {
            setSelectedPlugin(plugin);
            setShowSettings(true);
          }}
          className="text-primary hover:text-primary/90 flex items-center"
        >
          <Settings className="w-4 h-4 mr-1" />
          Settings
        </button>
        
        <button
          onClick={() => {
            // Toggle plugin status
            const newStatus = plugin.status === 'active' ? 'inactive' : 'active';
            // Update plugin status logic here
          }}
          className={`p-2 rounded ${
            plugin.status === 'active'
              ? 'text-green-600 hover:text-green-700'
              : 'text-gray-400 hover:text-gray-600'
          }`}
        >
          <Power className="w-5 h-5" />
        </button>
      </div>
    </div>
  );

  return (
    <AdminLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Plugins</h1>
            <p className="text-gray-500">Manage and configure your plugins</p>
          </div>
          <div className="flex items-center space-x-4">
            <button className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 flex items-center">
              <Plus className="w-5 h-5 mr-2" />
              Add New Plugin
            </button>
            <button className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100">
              <RefreshCw className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Search and View Toggle */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div className="relative flex-1 max-w-md">
              <input
                type="text"
                placeholder="Search plugins..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setView('grid')}
                className={`p-2 rounded ${
                  view === 'grid' ? 'text-primary bg-primary/10' : 'text-gray-400 hover:text-gray-600'
                }`}
              >
                <Grid className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Plugins Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPlugins.map((plugin) => (
            <PluginCard key={plugin.id} plugin={plugin} />
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Plugins;