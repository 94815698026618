import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Save,
  Image as ImageIcon,
  Link,
  Bold,
  Italic,
  List,
  AlertCircle,
  X,
  Upload
} from 'lucide-react';
import AdminLayout from '../../components/admin/AdminLayout';

const PostEditor = () => {
  const { id } = useParams(); // Untuk edit artikel yang sudah ada
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  
  // State untuk form
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    excerpt: '',
    content: '',
    category: '',
    tags: [],
    featuredImage: null,
    status: 'draft',
    meta: {
      title: '',
      description: ''
    }
  });

  // Categories data (dalam implementasi nyata akan diambil dari API)
  const categories = [
    { id: 'tech', name: 'Teknologi' },
    { id: 'marketing', name: 'Digital Marketing' },
    { id: 'design', name: 'Design' },
    { id: 'business', name: 'Bisnis' },
  ];

  // Fungsi untuk handle perubahan input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Fungsi untuk handle perubahan meta
  const handleMetaChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      meta: {
        ...prev.meta,
        [name]: value
      }
    }));
  };

  // Fungsi untuk generate slug dari title
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .trim();
  };

  // Handle title change dan generate slug
  const handleTitleChange = (e) => {
    const title = e.target.value;
    setFormData(prev => ({
      ...prev,
      title,
      slug: generateSlug(title)
    }));
  };

  // Handle upload featured image
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          featuredImage: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle tags input
  const handleTagInput = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (!formData.tags.includes(newTag)) {
        setFormData(prev => ({
          ...prev,
          tags: [...prev.tags, newTag]
        }));
      }
      e.target.value = '';
    }
  };

  // Remove tag
  const removeTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  return (
    <AdminLayout>
      <div className="max-w-5xl mx-auto space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              {id ? 'Edit Artikel' : 'Artikel Baru'}
            </h1>
            <p className="text-gray-500">
              {id ? 'Edit artikel yang sudah ada' : 'Buat artikel baru'}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigate('/admin/posts')}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
            >
              Batal
            </button>
            <button
              disabled={isSubmitting}
              className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 flex items-center"
            >
              <Save className="w-5 h-5 mr-2" />
              {isSubmitting ? 'Menyimpan...' : 'Simpan'}
            </button>
          </div>
        </div>

        {/* Alert */}
        {showAlert && (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 flex items-center justify-between">
            <div className="flex items-center">
              <AlertCircle className="w-5 h-5 text-red-600 mr-2" />
              <p className="text-red-600">Terjadi kesalahan. Silakan coba lagi.</p>
            </div>
            <button onClick={() => setShowAlert(false)}>
              <X className="w-5 h-5 text-red-600" />
            </button>
          </div>
        )}

        {/* Main Form */}
        <div className="grid grid-cols-3 gap-6">
          {/* Main Content Column */}
          <div className="col-span-2 space-y-6">
            {/* Title Input */}
            <div className="bg-white rounded-lg shadow p-6">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                Judul Artikel
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleTitleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
                placeholder="Masukkan judul artikel"
              />
            </div>

            {/* Slug Input */}
            <div className="bg-white rounded-lg shadow p-6">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 mb-1">
                Slug
              </label>
              <input
                type="text"
                id="slug"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
                placeholder="url-friendly-title"
              />
            </div>

            {/* Editor Toolbar */}
            <div className="bg-white rounded-lg shadow">
              <div className="border-b p-2 flex items-center space-x-2">
                <button className="p-2 hover:bg-gray-100 rounded">
                  <Bold className="w-5 h-5" />
                </button>
                <button className="p-2 hover:bg-gray-100 rounded">
                  <Italic className="w-5 h-5" />
                </button>
                <button className="p-2 hover:bg-gray-100 rounded">
                  <List className="w-5 h-5" />
                </button>
                <button className="p-2 hover:bg-gray-100 rounded">
                  <Link className="w-5 h-5" />
                </button>
                <button className="p-2 hover:bg-gray-100 rounded">
                  <ImageIcon className="w-5 h-5" />
                </button>
              </div>

              {/* Content Editor */}
              <div className="p-6">
                <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
                  Konten
                </label>
                <textarea
                  id="content"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  rows="12"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
                  placeholder="Tulis konten artikel di sini..."
                />
              </div>
            </div>

            {/* Excerpt */}
            <div className="bg-white rounded-lg shadow p-6">
              <label htmlFor="excerpt" className="block text-sm font-medium text-gray-700 mb-1">
                Excerpt
              </label>
              <textarea
                id="excerpt"
                name="excerpt"
                value={formData.excerpt}
                onChange={handleChange}
                rows="3"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
                placeholder="Ringkasan singkat artikel..."
              />
            </div>
          </div>
          </div>

{/* Sidebar Settings */}
<div className="col-span-1 space-y-6">
  {/* Publish Settings */}
  <div className="bg-white rounded-lg shadow p-6">
    <h3 className="font-medium text-gray-900 mb-4">Status Publikasi</h3>
    <select
      name="status"
      value={formData.status}
      onChange={handleChange}
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
    >
      <option value="draft">Draft</option>
      <option value="published">Published</option>
      <option value="archived">Archived</option>
    </select>
    <div className="mt-4 text-sm text-gray-500">
      {formData.status === 'published' ? (
        <p>Artikel akan langsung dipublikasikan</p>
      ) : (
        <p>Artikel akan disimpan sebagai draft</p>
      )}
    </div>
  </div>

  {/* Featured Image */}
  <div className="bg-white rounded-lg shadow p-6">
    <h3 className="font-medium text-gray-900 mb-4">Gambar Utama</h3>
    <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
      {formData.featuredImage ? (
        <div className="relative">
          <img
            src={formData.featuredImage}
            alt="Featured"
            className="w-full h-40 object-cover rounded"
          />
          <button
            onClick={() => setFormData(prev => ({ ...prev, featuredImage: null }))}
            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow"
          >
            <X className="w-4 h-4 text-gray-500" />
          </button>
        </div>
      ) : (
        <div className="text-center">
          <Upload className="w-8 h-8 text-gray-400 mx-auto mb-2" />
          <label htmlFor="featured-image" className="cursor-pointer">
            <span className="text-primary hover:text-primary/90">Upload gambar</span>
            <input
              type="file"
              id="featured-image"
              className="hidden"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </label>
          <p className="text-sm text-gray-500 mt-1">PNG, JPG, GIF hingga 2MB</p>
        </div>
      )}
    </div>
  </div>

  {/* Category */}
  <div className="bg-white rounded-lg shadow p-6">
    <h3 className="font-medium text-gray-900 mb-4">Kategori</h3>
    <select
      name="category"
      value={formData.category}
      onChange={handleChange}
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
    >
      <option value="">Pilih Kategori</option>
      {categories.map(category => (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      ))}
    </select>
  </div>

  {/* Tags */}
  <div className="bg-white rounded-lg shadow p-6">
    <h3 className="font-medium text-gray-900 mb-4">Tags</h3>
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2">
        {formData.tags.map(tag => (
          <span
            key={tag}
            className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100"
          >
            {tag}
            <button
              onClick={() => removeTag(tag)}
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              <X className="w-4 h-4" />
            </button>
          </span>
        ))}
      </div>
      <input
        type="text"
        placeholder="Tambah tag dan tekan Enter"
        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
        onKeyPress={handleTagInput}
      />
    </div>
  </div>

  {/* SEO Settings */}
  <div className="bg-white rounded-lg shadow p-6">
    <h3 className="font-medium text-gray-900 mb-4">SEO Settings</h3>
    <div className="space-y-4">
      <div>
        <label className="block text-sm text-gray-700 mb-1">
          Meta Title
        </label>
        <input
          type="text"
          name="title"
          value={formData.meta.title}
          onChange={handleMetaChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
          placeholder="SEO Title"
        />
      </div>
      <div>
        <label className="block text-sm text-gray-700 mb-1">
          Meta Description
        </label>
        <textarea
          name="description"
          value={formData.meta.description}
          onChange={handleMetaChange}
          rows="3"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
          placeholder="SEO Description"
        />
      </div>
    </div>
  </div>
</div>
</div>
</AdminLayout>
);
};

export default PostEditor;