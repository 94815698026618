import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const HeroBanner = () => {
  const slides = [
    {
      id: 1,
      image: '/assets/images/hero-1.jpg',
      title: 'Solusi Digital Terbaik',
      description: 'Kami membantu bisnis Anda bertransformasi ke era digital',
      buttonText: 'Pelajari Lebih Lanjut',
      buttonLink: '/services'
    },
    {
      id: 2,
      image: '/assets/images/hero-2.jpg',
      title: 'Kreativitas Tanpa Batas',
      description: 'Tim kreatif kami siap mewujudkan ide-ide brilian Anda',
      buttonText: 'Lihat Portfolio',
      buttonLink: '/portfolio'
    },
    {
      id: 3,
      image: '/assets/images/hero-3.jpg',
      title: 'Teknologi Terkini',
      description: 'Menggunakan teknologi terbaru untuk solusi modern',
      buttonText: 'Konsultasi Gratis',
      buttonLink: '/contact'
    }
  ];

  return (
    <div className="relative w-full h-[600px]">
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation
        loop={true}
        className="w-full h-full"
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="relative w-full h-full">
              {/* Background Image */}
              <div 
                className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${slide.image})` }}
              >
                <div className="absolute inset-0 bg-black/50" /> {/* Overlay */}
              </div>
              
              {/* Content */}
              <div className="relative h-full flex items-center">
                <div className="container mx-auto px-4 sm:px-6">
                  <div className="max-w-2xl text-white">
                    <h1 className="text-4xl md:text-5xl font-bold mb-4">
                      {slide.title}
                    </h1>
                    <p className="text-xl md:text-2xl mb-8">
                      {slide.description}
                    </p>
                    <a
                      href={slide.buttonLink}
                      className="inline-block bg-primary hover:bg-primary/90 text-white font-bold py-3 px-8 rounded-lg transition-colors"
                    >
                      {slide.buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroBanner;