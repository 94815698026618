import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Zap, Users } from 'lucide-react';
import Button from '../shared/Button';

const AboutSection = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: Shield,
      title: 'Terpercaya',
      description: 'Lebih dari 5 tahun pengalaman dalam industri teknologi dan kreativitas'
    },
    {
      icon: Zap,
      title: 'Inovatif',
      description: 'Selalu menggunakan teknologi terbaru untuk solusi terbaik'
    },
    {
      icon: Users,
      title: 'Kolaboratif',
      description: 'Bekerja sama dengan klien untuk mencapai hasil optimal'
    }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Image */}
          <div className="relative">
            <img
              src="/assets/images/about-image.jpg"
              alt="About Us"
              className="rounded-lg shadow-lg w-full"
            />
            <div className="absolute -bottom-6 -right-6 bg-primary text-white p-6 rounded-lg shadow-lg hidden md:block">
              <p className="text-4xl font-bold">5+</p>
              <p className="text-sm">Tahun Pengalaman</p>
            </div>
          </div>

          {/* Content */}
          <div className="space-y-6">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
              Menghadirkan Solusi Digital untuk Masa Depan yang Lebih Baik
            </h2>
            
            <p className="text-lg text-gray-600">
              Kami adalah tim profesional yang berkomitmen untuk memberikan solusi teknologi
              dan kreativitas terbaik untuk membantu bisnis Anda berkembang di era digital.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
              {features.map((feature) => {
                const Icon = feature.icon;
                return (
                  <div key={feature.title} className="text-center">
                    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-primary/10 text-primary mb-4">
                      <Icon className="h-6 w-6" />
                    </div>
                    <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                );
              })}
            </div>

            <div className="mt-8">
              <Button
                onClick={() => navigate('/about')}
                size="lg"
              >
                Pelajari Lebih Lanjut
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;