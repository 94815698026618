import React from 'react';
import { 
  Users, 
  FileText, 
  Eye, 
  ThumbsUp,
  TrendingUp,
  ArrowUpRight,
  ArrowDownRight 
} from 'lucide-react';
import AdminLayout from '../../components/admin/AdminLayout';

const Dashboard = () => {
  // Data statistik (dalam implementasi nyata, ini akan diambil dari API)
  const stats = [
    {
      title: 'Total Pengunjung',
      value: '12,345',
      change: '+12.3%',
      isIncrease: true,
      icon: Users,
      color: 'blue'
    },
    {
      title: 'Artikel Dipublish',
      value: '234',
      change: '+8.2%',
      isIncrease: true,
      icon: FileText,
      color: 'green'
    },
    {
      title: 'Total Views',
      value: '45,678',
      change: '+15.8%',
      isIncrease: true,
      icon: Eye,
      color: 'purple'
    },
    {
      title: 'Engagement Rate',
      value: '5.2%',
      change: '-2.4%',
      isIncrease: false,
      icon: ThumbsUp,
      color: 'orange'
    },
  ];

  // Data artikel terbaru
  const recentPosts = [
    {
      id: 1,
      title: 'Tren Teknologi 2024',
      views: 1234,
      comments: 12,
      date: '2024-01-10',
      status: 'published'
    },
    {
      id: 2,
      title: 'Tips Digital Marketing',
      views: 987,
      comments: 8,
      date: '2024-01-09',
      status: 'draft'
    },
    {
      id: 3,
      title: 'UI/UX Best Practices',
      views: 756,
      comments: 5,
      date: '2024-01-08',
      status: 'published'
    },
  ];

  // Data aktivitas terbaru
  const recentActivities = [
    {
      id: 1,
      type: 'comment',
      user: 'John Doe',
      action: 'mengomentari artikel',
      target: 'Tren Teknologi 2024',
      time: '5 menit yang lalu'
    },
    {
      id: 2,
      type: 'post',
      user: 'Jane Smith',
      action: 'membuat artikel baru',
      target: 'Tips Digital Marketing',
      time: '2 jam yang lalu'
    },
    {
      id: 3,
      type: 'edit',
      user: 'Mike Johnson',
      action: 'mengedit artikel',
      target: 'UI/UX Best Practices',
      time: '4 jam yang lalu'
    },
  ];

  return (
    <AdminLayout>
      <div className="space-y-6">
        {/* Page Title */}
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
          <p className="text-gray-500">Selamat datang kembali, Admin!</p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat) => {
            const Icon = stat.icon;
            return (
              <div key={stat.title} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className={`p-2 rounded-lg bg-${stat.color}-100`}>
                    <Icon className={`w-6 h-6 text-${stat.color}-600`} />
                  </div>
                  <div className="flex items-center space-x-1">
                    <span className={`text-sm ${
                      stat.isIncrease ? 'text-green-600' : 'text-red-600'
                    }`}>
                      {stat.change}
                    </span>
                    {stat.isIncrease ? (
                      <ArrowUpRight className="w-4 h-4 text-green-600" />
                    ) : (
                      <ArrowDownRight className="w-4 h-4 text-red-600" />
                    )}
                  </div>
                </div>
                <h3 className="text-2xl font-bold">{stat.value}</h3>
                <p className="text-gray-600">{stat.title}</p>
              </div>
            );
          })}
        </div>

        {/* Recent Posts & Activities Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Recent Posts */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-6 border-b">
              <h2 className="text-lg font-semibold">Artikel Terbaru</h2>
            </div>
            <div className="p-6">
              <div className="divide-y">
                {recentPosts.map((post) => (
                  <div key={post.id} className="py-4 first:pt-0 last:pb-0">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium">{post.title}</h3>
                        <p className="text-sm text-gray-500">
                          {post.views} views • {post.comments} comments
                        </p>
                      </div>
                      <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                        post.status === 'published' 
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {post.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <button className="mt-4 w-full py-2 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-100">
                Lihat Semua Artikel
              </button>
            </div>
          </div>

          {/* Recent Activities */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-6 border-b">
              <h2 className="text-lg font-semibold">Aktivitas Terbaru</h2>
            </div>
            <div className="p-6">
              <div className="space-y-6">
                {recentActivities.map((activity) => (
                  <div key={activity.id} className="flex space-x-4">
                    <div className="flex-shrink-0">
                      <img
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          activity.user
                        )}&background=0D8ABC&color=fff`}
                        alt={activity.user}
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <div>
                      <p>
                        <span className="font-medium">{activity.user}</span>{' '}
                        {activity.action}{' '}
                        <span className="font-medium">{activity.target}</span>
                      </p>
                      <p className="text-sm text-gray-500">{activity.time}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Performance Chart */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold">Performa Website</h2>
            <select className="border rounded-lg px-3 py-1">
              <option value="7">7 hari terakhir</option>
              <option value="30">30 hari terakhir</option>
              <option value="90">90 hari terakhir</option>
            </select>
          </div>
          {/* Di sini Anda bisa menambahkan komponen chart */}
          <div className="h-64 flex items-center justify-center bg-gray-50 rounded">
            <p className="text-gray-500">Chart akan ditampilkan di sini</p>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;