import React, { useState } from 'react';
import { Search, Calendar, User, Tag, ChevronRight } from 'lucide-react';

const Blog = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'Semua' },
    { id: 'tech', name: 'Teknologi' },
    { id: 'digital-marketing', name: 'Digital Marketing' },
    { id: 'design', name: 'Design' },
    { id: 'business', name: 'Bisnis' },
  ];

  const blogPosts = [
    {
      id: 1,
      title: 'Tren Teknologi yang Harus Diperhatikan di 2024',
      excerpt: 'Perkembangan teknologi yang semakin pesat membawa berbagai inovasi baru...',
      category: 'tech',
      author: 'John Doe',
      date: '10 Jan 2024',
      image: '/assets/images/blog/tech-trends.jpg',
      tags: ['Technology', 'Innovation', 'Future'],
    },
    {
      id: 2,
      title: 'Strategi Digital Marketing untuk UKM',
      excerpt: 'Bagaimana UKM dapat memanfaatkan digital marketing untuk pertumbuhan bisnis...',
      category: 'digital-marketing',
      author: 'Jane Smith',
      date: '8 Jan 2024',
      image: '/assets/images/blog/digital-marketing.jpg',
      tags: ['Marketing', 'SME', 'Digital'],
    },
    {
      id: 3,
      title: 'Prinsip Design Thinking dalam Pengembangan Produk',
      excerpt: 'Memahami dan menerapkan design thinking untuk menciptakan produk yang user-friendly...',
      category: 'design',
      author: 'Mike Johnson',
      date: '5 Jan 2024',
      image: '/assets/images/blog/design-thinking.jpg',
      tags: ['Design', 'Product Development', 'UX'],
    },
    // Tambahkan artikel blog lainnya di sini
  ];

  const filteredPosts = blogPosts.filter(post => {
    const matchesCategory = selectedCategory === 'all' || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-primary">
        <div className="absolute inset-0">
          <img
            src="/assets/images/blog-hero.jpg"
            alt="Blog"
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        <div className="relative container mx-auto px-4 sm:px-6 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Blog & Artikel
          </h1>
          <p className="text-xl text-white/90 max-w-2xl mx-auto">
            Temukan insight dan tips seputar teknologi, design, dan digital marketing
          </p>
        </div>
      </section>

      {/* Search and Filter Section */}
      <section className="py-8 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            {/* Search Bar */}
            <div className="relative w-full md:w-96">
              <input
                type="text"
                placeholder="Cari artikel..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-12 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>

            {/* Category Filter */}
            <div className="flex flex-wrap gap-2">
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setSelectedCategory(category.id)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                    ${selectedCategory === category.id
                      ? 'bg-primary text-white'
                      : 'bg-white text-gray-600 hover:bg-gray-100'
                    }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Blog Posts Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPosts.map((post) => (
              <article
                key={post.id}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
              >
                {/* Post Image */}
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
                
                {/* Post Content */}
                <div className="p-6">
                  {/* Category Badge */}
                  <span className="inline-block px-3 py-1 text-sm font-medium text-primary bg-primary/10 rounded-full mb-4">
                    {categories.find(cat => cat.id === post.category)?.name}
                  </span>
                  
                  {/* Title */}
                  <h2 className="text-xl font-bold mb-3 hover:text-primary">
                    <a href={`/blog/${post.id}`}>{post.title}</a>
                  </h2>
                  
                  {/* Excerpt */}
                  <p className="text-gray-600 mb-4">
                    {post.excerpt}
                  </p>
                  
                  {/* Meta Info */}
                  <div className="flex items-center text-sm text-gray-500 mb-4">
                    <User className="w-4 h-4 mr-1" />
                    <span className="mr-4">{post.author}</span>
                    <Calendar className="w-4 h-4 mr-1" />
                    <span>{post.date}</span>
                  </div>
                  
                  {/* Tags */}
                  <div className="flex flex-wrap gap-2">
                    {post.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full"
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                  
                  {/* Read More Link */}
                  <a
                    href={`/blog/${post.id}`}
                    className="inline-flex items-center text-primary hover:text-primary/90 mt-4 font-medium"
                  >
                    Baca Selengkapnya
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">
              Subscribe Newsletter
            </h2>
            <p className="text-gray-600 mb-8">
              Dapatkan artikel terbaru dan insight menarik langsung di inbox Anda
            </p>
            <form className="flex gap-4">
              <input
                type="email"
                placeholder="Masukkan email Anda"
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
              />
              <button
                type="submit"
                className="bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary/90 transition-colors"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;