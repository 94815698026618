import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import MenuItemModal from '../../components/admin/MenuItemModal';
import { Plus } from 'lucide-react';

const MenuBuilder = () => {
  const [selectedMenu, setSelectedMenu] = useState('main-menu');
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  // Menus data
  const menus = [
    { id: 'main-menu', name: 'Menu Utama' },
    { id: 'footer-menu', name: 'Menu Footer' },
    { id: 'mobile-menu', name: 'Menu Mobile' }
  ];

  return (
    <AdminLayout>
      <div className="max-w-4xl mx-auto space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Menu Builder</h1>
            <p className="text-gray-500">Customize your website navigation</p>
          </div>
          <button
            onClick={() => setShowAddItemModal(true)}
            className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 flex items-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Menu Item
          </button>
        </div>

        {/* Menu Selection */}
        <div className="bg-white rounded-lg shadow p-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Menu to Edit
          </label>
          <select
            value={selectedMenu}
            onChange={(e) => setSelectedMenu(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
          >
            {menus.map((menu) => (
              <option key={menu.id} value={menu.id}>
                {menu.name}
              </option>
            ))}
          </select>
        </div>

        {/* Menu Structure */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold mb-4">Menu Structure</h2>
          <div className="space-y-2">
            {/* Add your menu structure here */}
            <p className="text-gray-500">Drag and drop menu items to reorder them</p>
          </div>
        </div>

        {/* Add/Edit Menu Item Modal */}
        <MenuItemModal
          isOpen={showAddItemModal}
          onClose={() => {
            setShowAddItemModal(false);
            setEditingItem(null);
          }}
          item={editingItem}
        />
      </div>
    </AdminLayout>
  );
};

export default MenuBuilder;