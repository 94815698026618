import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navigation = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Software Utama');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Data struktur menu
  const menuData = {
    'Software Utama': [
      {
        title: 'Vizito',
        description: 'Software Management Visitor',
        icon: '🔵',
      },
      {
        title: 'Simple Sign',
        description: 'Digital Signature Platform',
        icon: '🟢',
      },
    ],
    'DigiTech': [
      {
        title: 'Pembuatan Website',
        description: 'Website Development Service',
        icon: '🌐',
      },
      {
        title: 'Mobile Apps',
        description: 'Mobile Application Development',
        icon: '📱',
      },
    ],
    'DigiArt': [
      {
        title: 'Pembuatan Konten',
        description: 'Digital Content Creation',
        icon: '🎨',
      },
      {
        title: 'Digital Marketing',
        description: 'Marketing Strategy & Campaign',
        icon: '📊',
      },
    ],
  };

  // Handle click outside to close dropdown
  const handleClickOutside = (e) => {
    if (!e.target.closest('.nav-dropdown')) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="hidden md:block relative">
        <div className="flex items-center justify-center space-x-8">
          <Link to="/" className="text-gray-700 hover:text-primary transition-colors">
            Beranda
          </Link>
          <Link to="/about" className="text-gray-700 hover:text-primary transition-colors">
            Tentang Kami
          </Link>
          <div className="relative nav-dropdown">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="text-gray-700 hover:text-primary transition-colors focus:outline-none"
            >
              Produk
            </button>

            {/* Mega Menu Dropdown */}
            {isDropdownOpen && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-[90vw] max-w-7xl bg-white shadow-xl rounded-lg mt-2 z-50">
                <div className="flex">
                  {/* Left Sidebar */}
                  <div className="w-64 min-h-[400px] bg-gray-50 p-6 rounded-l-lg">
                    <div className="font-medium text-gray-400 uppercase text-sm mb-4">
                      PRODUK DIGIDAY
                    </div>
                    <div className="space-y-1">
                      {Object.keys(menuData).map((category) => (
                        <button
                          key={category}
                          onClick={() => setSelectedCategory(category)}
                          className={`w-full text-left px-4 py-3 rounded-lg transition-all ${
                            selectedCategory === category
                              ? 'bg-white text-primary shadow-sm font-medium'
                              : 'text-gray-700 hover:bg-white'
                          }`}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Right Content */}
                  <div className="flex-1 p-8">
                    <div className="grid grid-cols-2 gap-8">
                      {menuData[selectedCategory].map((item) => (
                        <Link
                          key={item.title}
                          to={`/product/${item.title.toLowerCase().replace(/\s+/g, '-')}`}
                          className="flex items-start space-x-4 p-4 rounded-lg hover:bg-gray-50 transition-colors group"
                        >
                          <div className="flex-shrink-0 w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center group-hover:bg-primary/20 transition-colors">
                            <span className="text-2xl">{item.icon}</span>
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900 group-hover:text-primary transition-colors">
                              {item.title}
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 leading-relaxed">
                              {item.description}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Link to="/blog" className="text-gray-700 hover:text-primary transition-colors">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-700 hover:text-primary transition-colors">
            Kontak
          </Link>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <nav className="md:hidden">
        <div className="flex items-center justify-between">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-gray-700 hover:text-primary p-2"
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="fixed inset-0 bg-white z-50 pt-16">
            <div className="container mx-auto px-4">
              <div className="space-y-6">
                <Link
                  to="/"
                  className="block text-lg text-gray-700 hover:text-primary"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Beranda
                </Link>
                <Link
                  to="/about"
                  className="block text-lg text-gray-700 hover:text-primary"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Tentang Kami
                </Link>
                
                {/* Mobile Products Menu */}
                <div className="space-y-4">
                  <div className="font-medium text-gray-900">Produk</div>
                  {Object.entries(menuData).map(([category, items]) => (
                    <div key={category} className="ml-4">
                      <div className="font-medium text-gray-700 mb-2">{category}</div>
                      <div className="space-y-2 ml-4">
                        {items.map((item) => (
                          <Link
                            key={item.title}
                            to={`/product/${item.title.toLowerCase().replace(/\s+/g, '-')}`}
                            className="block text-gray-600 hover:text-primary"
                            onClick={() => setIsMobileMenuOpen(false)}
                          >
                            {item.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <Link
                  to="/blog"
                  className="block text-lg text-gray-700 hover:text-primary"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Blog
                </Link>
                <Link
                  to="/contact"
                  className="block text-lg text-gray-700 hover:text-primary"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Kontak
                </Link>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navigation;