import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

// Public Pages
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';

// Auth Pages
import Login from './pages/auth/Login';

// Admin Pages
import Dashboard from './pages/admin/Dashboard';
import Posts from './pages/admin/Posts';
import PostEditor from './pages/admin/PostEditor';
import Media from './pages/admin/Media';
import Comments from './pages/admin/Comments';
import Users from './pages/admin/Users';
import Settings from './pages/admin/Settings';
import MenuBuilder from './pages/admin/MenuBuilder';
import Analytics from './pages/admin/Analytics';
import Plugins from './pages/admin/Plugins';
import Profile from './pages/admin/Profile';

// Components
import ProtectedRoute from './components/auth/ProtectedRoute';

function App() {
  // Function to check if current path is an admin route
  const isAdminRoute = () => {
    return window.location.pathname.startsWith('/admin');
  };

  // Function to check if current path is login page
  const isLoginPage = () => {
    return window.location.pathname === '/login';
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        {/* Only show Header if not admin route and not login page */}
        {!isAdminRoute() && !isLoginPage() && <Header />}
        
        <main className="flex-1">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />

            {/* Protected Admin Routes */}
            <Route path="/admin" element={<ProtectedRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="posts" element={<Posts />} />
              <Route path="posts/new" element={<PostEditor />} />
              <Route path="posts/edit/:id" element={<PostEditor />} />
              <Route path="media" element={<Media />} />
              <Route path="comments" element={<Comments />} />
              <Route path="users" element={<Users />} />
              <Route path="settings" element={<Settings />} />
              <Route path="menus" element={<MenuBuilder />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="plugins" element={<Plugins />} />
              <Route path="profile" element={<Profile />} /> {/* New Profile Route */}
            </Route>

            {/* 404 Route */}
            <Route path="*" element={
              <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                  <h1 className="text-4xl font-bold text-gray-900 mb-4">404</h1>
                  <p className="text-gray-600">Page not found</p>
                </div>
              </div>
            } />
          </Routes>
        </main>

        {/* Only show Footer if not admin route and not login page */}
        {!isAdminRoute() && !isLoginPage() && <Footer />}
      </div>
    </Router>
  );
}

export default App;