import React from 'react';
import { Code, Palette, Database, Globe, Camera, LineChart } from 'lucide-react';
import Card from '../shared/Card';

const ServicesSection = () => {
  const services = [
    {
      icon: Code,
      title: 'Pengembangan Software',
      description: 'Pembuatan aplikasi web, mobile, dan desktop dengan teknologi terkini',
      category: 'tech'
    },
    {
      icon: Database,
      title: 'Sistem Informasi',
      description: 'Sistem manajemen bisnis, ERP, dan solusi berbasis cloud',
      category: 'tech'
    },
    {
      icon: Palette,
      title: 'Desain UI/UX',
      description: 'Desain antarmuka yang menarik dan mudah digunakan',
      category: 'creative'
    },
    {
      icon: Camera,
      title: 'Konten Kreatif',
      description: 'Produksi foto, video, dan konten digital berkualitas',
      category: 'creative'
    },
    {
      icon: Globe,
      title: 'Digital Marketing',
      description: 'Strategi pemasaran digital yang efektif dan terukur',
      category: 'creative'
    },
    {
      icon: LineChart,
      title: 'Konsultasi IT',
      description: 'Konsultasi teknologi untuk pengembangan bisnis Anda',
      category: 'tech'
    }
  ];

  return (
    <section className="py-16">
      <div className="container mx-auto px-4 sm:px-6">
        {/* Header */}
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Layanan Kami
          </h2>
          <p className="text-lg text-gray-600">
            Solusi lengkap untuk kebutuhan teknologi dan kreativitas bisnis Anda
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => {
            const Icon = service.icon;
            return (
              <Card
                key={service.title}
                hover
                className="text-center"
              >
                <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/10 text-primary mb-6">
                  <Icon className="h-8 w-8" />
                </div>
                <h3 className="text-xl font-semibold mb-3">
                  {service.title}
                </h3>
                <p className="text-gray-600">
                  {service.description}
                </p>
                {/* Category Badge */}
                <div className={`
                  inline-block mt-4 px-3 py-1 rounded-full text-sm font-medium
                  ${service.category === 'tech' 
                    ? 'bg-blue-100 text-blue-800' 
                    : 'bg-purple-100 text-purple-800'
                  }
                `}>
                  {service.category === 'tech' ? 'Teknologi' : 'Kreatif'}
                </div>
              </Card>
            );
          })}
        </div>

        {/* CTA */}
        <div className="text-center mt-12">
          <a 
            href="/services" 
            className="inline-flex items-center text-primary hover:text-primary/90 font-medium"
          >
            Lihat Semua Layanan
            <svg 
              className="w-5 h-5 ml-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 8l4 4m0 0l-4 4m4-4H3" 
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;