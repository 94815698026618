import React from 'react';
import HeroBanner from '../components/home/HeroBanner';
import AboutSection from '../components/home/AboutSection';
import ServicesSection from '../components/home/ServicesSection';
import TestimonialsSection from '../components/home/TestimonialsSection';

const Home = () => {
  return (
    <div>
      <HeroBanner />
      <AboutSection />
      <ServicesSection />
      <TestimonialsSection />
    </div>
  );
};

export default Home;