// pages/admin/Media.js
import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { Upload, Trash2, Search, FolderPlus, List, Grid } from 'lucide-react';

const Media = () => {
  const [view, setView] = useState('grid');
  const [mediaFiles, setMediaFiles] = useState([
    {
      id: 1,
      name: 'hero-image.jpg',
      type: 'image',
      url: '/assets/images/hero-1.jpg',
      size: '1.2 MB',
      uploadedAt: '2024-01-10'
    },
    // Tambahkan media lainnya
  ]);

  return (
    <AdminLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Media Library</h1>
            <p className="text-gray-500">Kelola semua media Anda</p>
          </div>
          <button className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 flex items-center">
            <Upload className="w-5 h-5 mr-2" />
            Upload Media
          </button>
        </div>

        {/* Filter & Search */}
        <div className="bg-white rounded-lg shadow p-6 flex items-center justify-between">
          <div className="flex space-x-4">
            <button
              onClick={() => setView('grid')}
              className={`p-2 rounded ${view === 'grid' ? 'bg-primary text-white' : 'text-gray-600'}`}
            >
              <Grid className="w-5 h-5" />
            </button>
            <button
              onClick={() => setView('list')}
              className={`p-2 rounded ${view === 'list' ? 'bg-primary text-white' : 'text-gray-600'}`}
            >
              <List className="w-5 h-5" />
            </button>
          </div>
          <div className="flex-1 mx-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Cari media..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>
          <button className="flex items-center text-gray-600 hover:text-primary">
            <FolderPlus className="w-5 h-5 mr-2" />
            Folder Baru
          </button>
        </div>

        {/* Media Grid/List */}
        <div className={view === 'grid' ? 'grid grid-cols-4 gap-6' : 'space-y-4'}>
          {mediaFiles.map((file) => (
            <div
              key={file.id}
              className={`bg-white rounded-lg shadow ${
                view === 'grid' ? 'p-4' : 'p-6 flex items-center'
              }`}
            >
              {view === 'grid' ? (
                <>
                  <img
                    src={file.url}
                    alt={file.name}
                    className="w-full h-40 object-cover rounded-lg mb-4"
                  />
                  <div>
                    <h3 className="font-medium truncate">{file.name}</h3>
                    <p className="text-sm text-gray-500">{file.size}</p>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={file.url}
                    alt={file.name}
                    className="w-20 h-20 object-cover rounded-lg mr-6"
                  />
                  <div className="flex-1">
                    <h3 className="font-medium">{file.name}</h3>
                    <p className="text-sm text-gray-500">
                      {file.size} • {file.uploadedAt}
                    </p>
                  </div>
                  <button className="text-red-500 hover:text-red-600">
                    <Trash2 className="w-5 h-5" />
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Media;