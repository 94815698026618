import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import {
  MessageSquare,
  Search,
  Filter,
  Check,
  X,
  Flag,
  MoreVertical,
  Eye,
  Edit2,
  Trash2,
  MessageCircle,
} from 'lucide-react';

const Comments = () => {
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedComment, setSelectedComment] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);

  // Dummy data untuk komentar
  const [comments] = useState([
    {
      id: 1,
      author: 'John Doe',
      email: 'john@example.com',
      content: 'Great article! Really helpful information.',
      post: 'Top 10 Tips for Digital Marketing',
      status: 'approved',
      date: '2024-01-10 09:30',
      replies: [],
      isReported: false,
    },
    {
      id: 2,
      author: 'Jane Smith',
      email: 'jane@example.com',
      content: 'I disagree with some points, but overall good read.',
      post: 'Web Development Trends 2024',
      status: 'pending',
      date: '2024-01-09 15:45',
      replies: [
        {
          id: 21,
          author: 'Admin',
          content: 'Thank you for your feedback! Could you specify which points?',
          date: '2024-01-09 16:00',
        }
      ],
      isReported: false,
    },
    {
      id: 3,
      author: 'Spam Bot',
      email: 'spam@example.com',
      content: 'Buy cheap products! Visit our website...',
      post: 'UI/UX Design Principles',
      status: 'spam',
      date: '2024-01-08 12:20',
      replies: [],
      isReported: true,
    },
  ]);

  // Status options
  const statusOptions = [
    { value: 'all', label: 'All Comments' },
    { value: 'pending', label: 'Pending' },
    { value: 'approved', label: 'Approved' },
    { value: 'spam', label: 'Spam' },
    { value: 'reported', label: 'Reported' },
  ];

  // Filter comments based on search and status
  const filteredComments = comments.filter(comment => {
    const matchesSearch = (
      comment.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
      comment.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
      comment.post.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const matchesStatus = selectedStatus === 'all' || 
                         (selectedStatus === 'reported' ? comment.isReported : comment.status === selectedStatus);
    return matchesSearch && matchesStatus;
  });

  // Get status badge style
  const getStatusBadgeStyle = (status) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'spam':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Comment Detail Modal Component
  const CommentDetailModal = ({ isOpen, onClose, comment }) => {
    if (!isOpen || !comment) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg max-w-2xl w-full p-6">
          {/* Modal content here */}
        </div>
      </div>
    );
  };

  // Main component return
  return (
    <AdminLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Comments Management</h1>
            <p className="text-gray-500">Monitor and moderate comments</p>
          </div>
        </div>

        {/* Filters */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Search */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search comments..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>

            {/* Status Filter */}
            <div className="relative">
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary appearance-none"
              >
                {statusOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <Filter className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>
        </div>

        {/* Comments Table */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {/* Table content here */}
        </div>

        {/* Comment Detail Modal */}
        <CommentDetailModal
          isOpen={showDetailModal}
          onClose={() => {
            setShowDetailModal(false);
            setSelectedComment(null);
          }}
          comment={selectedComment}
        />
      </div>
    </AdminLayout>
  );
};

export default Comments;