import React, { useState } from 'react';
import { 
  Code, 
  Database, 
  Globe, 
  Smartphone, 
  Monitor, 
  Cloud,
  Palette,
  Camera,
  Video,
  MessageSquare,
  BarChart,
  PenTool
} from 'lucide-react';

const Services = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'Semua Layanan' },
    { id: 'tech', name: 'Teknologi' },
    { id: 'creative', name: 'Kreatif' },
  ];

  const services = [
    // Teknologi
    {
      icon: Code,
      title: 'Web Development',
      description: 'Pembuatan website modern dengan teknologi terkini. Fokus pada performa, keamanan, dan SEO.',
      features: [
        'Frontend Development',
        'Backend Development',
        'Website Responsif',
        'Integrasi API',
      ],
      category: 'tech',
    },
    {
      icon: Smartphone,
      title: 'Mobile App Development',
      description: 'Pengembangan aplikasi mobile untuk Android dan iOS dengan pengalaman pengguna terbaik.',
      features: [
        'Native Android/iOS',
        'Cross-platform Development',
        'UI/UX Design',
        'App Maintenance',
      ],
      category: 'tech',
    },
    {
      icon: Database,
      title: 'Sistem Informasi',
      description: 'Sistem manajemen yang disesuaikan dengan kebutuhan bisnis Anda.',
      features: [
        'ERP System',
        'CRM System',
        'Inventory Management',
        'Custom Software',
      ],
      category: 'tech',
    },
    {
      icon: Cloud,
      title: 'Cloud Solutions',
      description: 'Solusi cloud untuk skalabilitas dan efisiensi bisnis.',
      features: [
        'Cloud Migration',
        'Cloud Architecture',
        'DevOps Services',
        'Maintenance & Support',
      ],
      category: 'tech',
    },

    // Kreatif
    {
      icon: Palette,
      title: 'UI/UX Design',
      description: 'Desain antarmuka yang menarik dan mudah digunakan.',
      features: [
        'User Research',
        'Wireframing',
        'Prototyping',
        'Visual Design',
      ],
      category: 'creative',
    },
    {
      icon: Camera,
      title: 'Photography',
      description: 'Layanan fotografi profesional untuk kebutuhan bisnis.',
      features: [
        'Product Photography',
        'Corporate Photography',
        'Event Coverage',
        'Photo Editing',
      ],
      category: 'creative',
    },
    {
      icon: Video,
      title: 'Video Production',
      description: 'Produksi video berkualitas untuk marketing dan dokumentasi.',
      features: [
        'Company Profile',
        'Product Videos',
        'Social Media Content',
        'Event Documentation',
      ],
      category: 'creative',
    },
    {
      icon: Globe,
      title: 'Digital Marketing',
      description: 'Strategi pemasaran digital yang efektif dan terukur.',
      features: [
        'Social Media Marketing',
        'Content Marketing',
        'SEO/SEM',
        'Email Marketing',
      ],
      category: 'creative',
    },
  ];

  const filteredServices = activeCategory === 'all' 
    ? services 
    : services.filter(service => service.category === activeCategory);

  return (
    <div>
      {/* Hero Section */}
      <section className="relative py-20 bg-primary">
        <div className="absolute inset-0">
          <img
            src="/assets/images/services-hero.jpg"
            alt="Our Services"
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        <div className="relative container mx-auto px-4 sm:px-6 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Layanan Kami
          </h1>
          <p className="text-xl text-white/90 max-w-2xl mx-auto">
            Solusi lengkap untuk kebutuhan teknologi dan kreativitas bisnis Anda
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16">
        <div className="container mx-auto px-4 sm:px-6">
          {/* Category Filter */}
          <div className="flex justify-center mb-12">
            <div className="inline-flex p-1 bg-gray-100 rounded-lg">
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  className={`px-6 py-2 rounded-md text-sm font-medium transition-colors
                    ${activeCategory === category.id
                      ? 'bg-white text-primary shadow'
                      : 'text-gray-600 hover:text-gray-900'
                    }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredServices.map((service) => {
              const Icon = service.icon;
              return (
                <div
                  key={service.title}
                  className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
                >
                  <div className="inline-flex items-center justify-center w-12 h-12 rounded-lg bg-primary/10 text-primary mb-6">
                    <Icon className="h-6 w-6" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                  <p className="text-gray-600 mb-4">{service.description}</p>
                  <ul className="space-y-2">
                    {service.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-600">
                        <span className="w-1.5 h-1.5 bg-primary rounded-full mr-2"></span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6">
          <h2 className="text-3xl font-bold text-center mb-12">
            Proses Kerja Kami
          </h2>
          <div className="max-w-4xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {[
                {
                  step: '01',
                  title: 'Konsultasi',
                  description: 'Diskusi kebutuhan dan solusi yang tepat'
                },
                {
                  step: '02',
                  title: 'Perencanaan',
                  description: 'Menyusun strategi dan timeline project'
                },
                {
                  step: '03',
                  title: 'Pengerjaan',
                  description: 'Eksekusi project sesuai rencana'
                },
                {
                  step: '04',
                  title: 'Evaluasi',
                  description: 'Review dan penyempurnaan hasil'
                }
              ].map((item) => (
                <div key={item.step} className="text-center">
                  <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-primary text-white font-bold mb-4">
                    {item.step}
                  </div>
                  <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-primary">
        <div className="container mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Siap Memulai Project?
          </h2>
          <p className="text-white/90 mb-8 max-w-2xl mx-auto">
            Konsultasikan kebutuhan Anda dengan tim kami dan dapatkan solusi terbaik
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-primary font-bold py-3 px-8 rounded-lg hover:bg-gray-100 transition-colors"
          >
            Hubungi Kami
          </a>
        </div>
      </section>
    </div>
  );
};

export default Services;